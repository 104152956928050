<template>
    <div id="index" ref="appRef">
        <div class="bg">
            <!-- 头部 -->
            <div class="head">
                <div style="position: absolute;top: 50%;left: 30px;color: #65ffff;font-size: 32px;display: flex;flex-direction: row;align-items: center;"
                    @click="back">
                    <span>◁</span><span style="margin-top: -4px;">━</span>
                    <span v-if="lang == 'zh'" style="margin-left: 8px;font-size: 22px;">返回</span>
                    <span v-else style="margin-left: 8px;font-size: 22px;">go back</span>
                </div>
                <div style="font-size: 50px;text-align: center;color: #fff;">电池监测界面</div>
                <div style="font-size: 27px;text-align: center;color: #fff;">BATTERY MONITORING INTERFACE</div>
            </div>
            

        </div>
    </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";

export default {
    mixins: [drawMixin],
    name: '',
    components: {},
    props: {},
    data() {
        return {
            lang: 'zh',
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
        if (localStorage.getItem('lang')) {
            this.lang = localStorage.getItem('lang')
        }
    },
    methods: {
        // 返回
        back() {
            this.$router.back();
        },
    }
}
</script>
<style lang='scss' scoped>
#index {
    color: #d3d6dd;
    width: 1920px;
    height: 1080px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    overflow: hidden;

    .bg {
        width: 100%;
        height: 100%;
        padding: 16px 16px 0 16px;
        background-image: url("../../assets/pageBg.png");
        background-size: cover;
        background-position: center center;
    }
}

.head {
    position: relative;
    width: 100%;
    height: 11vh;
    background: url("../../assets/BMS/Group 70@2x.png") no-repeat;
    background-size: cover;
    background-position: center center;
}
</style>